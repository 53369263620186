import { useEffect } from 'react';
import {
  Box,
  Container,
  VStack,
  HStack,
  Flex,
  Spacer,
  Text,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import Link from 'app/components/Link';
import Role from 'app/components/auth/Role';
import { AccountProfile, useAccountProfileGet } from 'app/data';
import useAuthStore from 'app/stores/auth';
import * as analytics from 'app/analytics';

interface HeaderProps {
  authenticated: boolean;
  accountProfile: AccountProfile | undefined;
}

export const Header = (_props: HeaderProps) => {
  const logoText = useBreakpointValue(
    {
      base: 'OT',
      md: 'Outside Therapy',
    },
    { ssr: false }
  );

  const workbookMenuName = useBreakpointValue(
    {
      base: 'Workbook',
      md: 'My Workbook',
    },
    { ssr: false }
  );
  const activityLibraryMenuName = useBreakpointValue(
    {
      base: 'Library',
      md: 'Activity Library',
    },
    { ssr: false }
  );

  return (
    <Flex
      w="100%"
      borderBottom="1px solid #eaeaea"
      align="center"
      justify="center"
      position="fixed"
      top="0"
      background="white"
      zIndex="sticky"
    >
      <Flex
        w="100%"
        align="center"
        minW="100px"
        maxW="96em"
        py={{ base: 2, md: 4 }}
        px={{ base: 4, md: 8 }}
      >
        <HStack gap={{ base: 3, md: 6 }}>
          <Link.Router to="/">
            <Heading
              fontSize="2xl"
              fontWeight="semibold"
              fontFamily="DM Serif Text, serif"
              maxW={{ base: '80px', md: '200px' }}
            >
              {logoText}
            </Heading>
          </Link.Router>
          <Spacer />
          <HStack gap={5}>
            <Role.Provider>
              <Link to="/dashboard">
                <Text>Dashboard</Text>
              </Link>
            </Role.Provider>
            <Role.Client>
              <Link to="/workbook">
                <Text>{workbookMenuName}</Text>
              </Link>
            </Role.Client>
            <Link to="/templates">
              <Text>{activityLibraryMenuName}</Text>
            </Link>
            <Link to="/guide">
              <Text>CBT Guide</Text>
            </Link>
          </HStack>
        </HStack>
        <Spacer />
      </Flex>
    </Flex>
  );
};

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Flex
      minH="100%"
      direction={{ base: 'column', md: 'row' }}
      p={8}
      pr={{ base: 8, md: 16 }}
      pl={{ base: 8, md: 16 }}
      align="center"
    >
      <Text
        fontSize="xs"
        color="gray.500"
        mb={{ base: 4, md: 0 }}
        mr={{ base: 0, md: 4 }}
      >
        Copyright © {year} Intuition Technologies, Inc
      </Text>
    </Flex>
  );
};

interface BasePageProps {
  title?: string;
  children: React.ReactNode;
  background?: string;
}

export const BasePage = ({ title, children, background }: BasePageProps) => {
  const [authenticated] = useAuthStore((state) => [!!state.token]);
  const { data: accountProfile } = useAccountProfileGet({
    query: { enabled: authenticated },
  });

  useEffect(() => {
    if (accountProfile) {
      analytics.identify(accountProfile.id);
      Sentry.setUser({ id: accountProfile.id });
    }
  }, [Sentry, accountProfile, analytics.identify]);

  return (
    <>
      {title && (
        <Helmet>
          <title>{title} - Outside Therapy</title>
        </Helmet>
      )}
      <Box maxW="100%" minH="100vh" p="0" background={background}>
        <VStack
          minH="100vh"
          spacing={0}
          align="stretch"
          justify="space-between"
        >
          <VStack spacing={0} align="stretch">
            <Header
              authenticated={authenticated}
              accountProfile={accountProfile}
            />

            <Box p={0} mt={20} mb={4} w="100%">
              {children}
            </Box>
          </VStack>

          <Footer />
        </VStack>
      </Box>
    </>
  );
};

// XXX expose section maxWidth and padding as props
export const Section = ({ children }: { children: React.ReactNode }) => (
  <Flex align="center" justify="center">
    <Container m={0} pt={8} pb={8} maxW="80em">
      {children}
    </Container>
  </Flex>
);

interface PageProps extends BasePageProps {
  panelWidth?: string;
}

const Page = ({ title, children, panelWidth = '54em' }: PageProps) => {
  return (
    <BasePage title={title} background="gray.100">
      <Container
        p={{ base: 4, md: 8 }}
        maxW={panelWidth}
        background="white"
        borderRadius={8}
      >
        <VStack align="start" spacing={4} w="100%">
          {children}
        </VStack>
      </Container>
    </BasePage>
  );
};

export default Page;
